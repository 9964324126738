import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "events" }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventsList = _resolveComponent("EventsList")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Pattern = _resolveComponent("Pattern")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EventsList, {
        class: "eventList",
        upcomingEvents: _ctx.upcomingEvents,
        archiveEvents: _ctx.archiveEvents
      }, null, 8, ["upcomingEvents", "archiveEvents"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Footer)
      ])
    ]),
    (_ctx.rendered)
      ? (_openBlock(), _createBlock(_component_Pattern, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}