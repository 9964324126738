import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "eventsList" }
const _hoisted_2 = { class: "link" }
const _hoisted_3 = { class: "date" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "link" }
const _hoisted_6 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upcomingEvents, (event) => {
        return (_openBlock(), _createElementBlock("li", {
          key: event.id
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'Event', params: { slug: event.slug }}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.formatOpeningTimes(event.startDate, event.endDate)), 1),
                _createElementVNode("h2", _hoisted_4, _toDisplayString(event.title), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "subheading" }, "Archive", -1)),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archiveEvents, (event) => {
        return (_openBlock(), _createElementBlock("li", {
          key: event.id
        }, [
          _createVNode(_component_router_link, {
            to: { name: 'Event', params: { slug: event.slug }}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(event.title), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ]))
}